<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <all-auctions-on-categories-pie-chart />
            </v-col>
            <v-col cols="12" md="6">
                <all-auctions-on-status-pie-chart />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    components: {
        AllAuctionsOnCategoriesPieChart: () =>
            import(
                "@/components/auctions/charts/AllAuctionsOnCategoriesPieChart"
            ),
        AllAuctionsOnStatusPieChart: () =>
            import("@/components/auctions/charts/AllAuctionsOnStatusPieChart")
    }
};
</script>
